<template>
  <!-- 新闻内容 -->
  <div class="news_cont_zong">
    <div class="item2">
      <div class="item2_cont">
        <div
          class="item2_cont1"
          v-for="(item, index) in arrList"
          :key="index"
          @click="funUrl(index)"
        >
          <img v-lazy="item.url" alt="" lazy class="imgss"/>
          <div class="item2_cont1_text">
            <div class="item2_cont1_text_top">
              <h1>
                {{ item.h1 }}
              </h1>
              <p>
                {{ item.p1 }}
              </p>
            </div>
            <div class="item2_cont1_text_btm">
              <div>{{item.date}}</div>
              <p>查看更多></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      arrList: [
        {
          date:"2023-01-01",
          url: require("../../assets/img/bg11.png"),
          h1: "小和出行深谙用户使用需求及行业产品痛点，深入了解校园出行的需求，打造行业领先产品",
          p1: "小和出行共享电动滑板车运营系统包括用户端、运维端及运营平台在内的、支撑共享电动滑板车企业整个运营过程的软件平台。 该软件系统通过大数据、云计算等技术手段，将智能中控反馈的实时信息以数字化呈现..."
        },
        {
          date:"2023-2-15",
          url: require("../../assets/img/great.png"),
          h1: "小和出行：太阳能无线充电共享电动滑板车使用流程",
          p1: "和传统共享单车、共享电动滑板车一样，打开微信扫一扫，使用小和出行小程序扫码骑行， 还车时，找到小和出行太阳能充电棚，将车辆推进指定区域即可还车成功...",
        },
        {
          date:"2023-02-24",
          url: require("../../assets/img/bg22.png"),
          h1: "小和出行: 将光伏发电无线充电系统应用于共享两轮出行",
          p1: "小和出行太阳能无线充电系统，提高共享出行智能化; 通过实施数据采集及大数据分析、为城市、学校、景区等提供精准的用户需求和调度...",
        },
        // {
        
        //   url: require("../../assets/img/youjiao/摄图网_501111552_banner_幼儿园老师指导画画（非企业商用）.png"),
        //   h1: "用系统思维下好“职业教育一盘大棋”",
        //   p1: "党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...",
        // },
        //{
   
        //   url: require("../../assets/img/youjiao/摄图网_501111552_banner_幼儿园老师指导画画（非企业商用）.png"),
        //   h1: "用系统思维下好“职业教育一盘大棋”",
        //   p1: "党的十九届五中全会审议通过的《中共中央关于制定国民经济和社会发展第十四个五年规划和二○三五远景目标的建议》内含了多条逻辑线...",
        // },
      ],
    };
  },
  methods: {
    funUrl(index) {
      if (index < 3) {
        this.$router.push("/newsxiangqing" + index);
      } else {
        alert("页面未创建无法跳转");
      }
    },
  },
};
</script>

<style scoped>
/* 新闻列表 */
.item_cont {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
.item2_cont1:first-child {
  display: flex;
  width: 1200px;
  height: 252px;
  background: #ffffff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item_cont1 img {
  margin: 32px 48px 32px 32px;
  width: 300px;
  /* height: 218px; */
  height: 188px;
  /* border: dashed; */
}
.imgss{
  margin: 32px 48px 32px 32px;
  width: 300px;
  /* height: 218px; */
  height: 188px;
  /* border: dashed; */
}
.item_cont1_text {
  width: 868px;
}
.item_cont1_text_top {
  margin: 45px 0 16px 0;
  width: 732px;
  height: 112px;

  border-bottom: 1px solid #eaeaea;
}
.item_cont1_text_top > h1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 24px;
}
.item_cont1_text_top p {
  margin-top: 16px;
  width: 732px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.item_cont1_text_btm {
  width: 732px;

  display: flex;
  justify-content: space-between;
}
.item_cont1_text_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}
.item_cont1_text_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}
/* item2 */
.item2_cont {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 30px;
}
.item2_cont1 {
  margin-top: 30px;
  display: flex;
  width: 1200px;
  height: 252px;

  /* background: #f8f8f8; */
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item2_cont1 img {
  margin: 32px 48px 32px 32px;
  width: 300px;
  height: 188px;
  /* border: dashed; */
}
.item2_cont1_text {
  width: 868px;
}
.item2_cont1_text_top {
  margin: 45px 0 16px 0;
  width: 732px;
  height: 112px;

  border-bottom: 1px solid #eaeaea;
}
.item2_cont1_text_top > h1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;

  color: #3f3f3f;
  line-height: 24px;
}
.item2_cont1_text_top p {
  margin-top: 16px;
  width: 732px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.item2_cont1_text_btm {
  width: 732px;

  display: flex;
  justify-content: space-between;
}
.item2_cont1_text_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

  color: #999999;
  line-height: 28px;
}
.item2_cont1_text_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}
</style>